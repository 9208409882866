import React from "react";

export const NEWS2024 = {
  name: "2024",
  news: [
    {
        date: "Mar. 15, 2024",
        content: (
          <span>
            Jiayi Hong's paper "Carbon Emission in Football Games: Footprint Impact of Power Five Conference Realignment," has been accepted to Sustainability and Sports Science Journal. Congratulations Jiayi!
          </span>
        ),
    },
    {
      date: "Mar. 7, 2024",
      content: (
        <span>
          Rostyslav Hnatyshyn and Jiayi Hong's paper "Capturing Cancer as Music: Cancer Mechanisms Expressed through Musification," has been accepted to ACM CHI 2024. Congratulations Rosty and Jiayi!
        </span>
      ),
    },
    {
      date: "Mar. 7, 2024",
      content: (
        <span>
          Fan Lei and Arlen Fan's paper "Understanding Reader Takeaways in Thematic Maps Under Varying Text, Detail, and Spatial Autocorrelation," has been accepted to ACM CHI 2024. Congratulations Fan and Arlen!
        </span>
      ),
    },
    {
        date: "Jan. 12, 2024",
        content: (
          <span>
            Jiayi Hong and Rostyslav Hnatyshyn's paper "A Survey of Designs for Combined 2D+3D Visual Representations," has been accepted to PacificVis 2024. Congratulations Jiayi and Rosty!
          </span>
        ),
      }
  ],
};
