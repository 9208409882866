import React from "react";

export const NEWS2023 = {
  name: "2023",
  news: [
    {
      date: "Oct. 3, 2023",
      content: (
        <span>
          Fan Lei and Arlen Fan's paper "GeoLinter: A Linting Framework for Choropleth Maps," has been accepted to IEEE TVCG. Congratulations Fan and Arlen!
        </span>
      ),
    },
    {
      date: "Sept. 4, 2023",
      content: (
        <span>
          Jiayi Hong's paper "Visualizing and Comparing Machine Learning Predictions to Improve Human-AI Teaming on the Example of Cell Lineage," has been accepted to IEEE TVCG. Congratulations Jiayi!
        </span>
      ),
    },
    {
      date: "Aug. 1, 2023",
      content: (
        <span>
          Jiayi Hong's paper "Visualizing and Comparing Machine Learning Predictions to Improve Human-AI Teaming on the Example of Cell Lineage," has been accepted to IEEE TVCG. Congratulations Jiayi!
        </span>
      ),
    },
    {
        date: "Jul. 15, 2023",
        content: (
          <span>
            Fan Lei's paper "GeoExplainer: A Visual Analytics Framework for Spatial Modeling Contextualization and Report Generation," has been accepted to IEEE VIS 2023. Congratulations Fan!
          </span>
        ),
    },
    {
        date: "Jul. 15, 2023",
        content: (
          <span>
            Rostyslav Hnatyshyn's paper "MolSieve: A Progressive Visual Analytics System for Molecular Dynamics Simulations," has been accepted to IEEE VIS 2023. Congratulations Rosty!
          </span>
        ),
    },
    {
        date: "Jul. 6, 2023",
        content: (
          <span>
            Tiankai Xie has joined as a postdoctoral research associate in VADER lab.
          </span>
        ),
    },
    {
        date: "Jul. 6, 2023",
        content: (
          <span>
            Tiankai Xie successfully defended his PhD thesis ("Explaining the vulnerabilities of machine learning through visual analytics")! Congratulations!
          </span>
        ),
    },
    {
        date: "Jun. 30, 2023",
        content: (
          <span>
            Fan Lei has been accepted to present at the IEEE VIS 2023 Doctoral Colloquium. Congratulations!
          </span>
        ),
    },
    {
        date: "May. 30, 2023",
        content: (
          <span>
           PostDoc Jieqiong Zhao has accepted an offer to become an assistant professor in School of Computer and Cyber Sciences, Augusta University. Congratulations Jieqiong!
          </span>
        ),
    },
    {
        date: "May. 26, 2023",
        content: (
          <span>
            Dr. Maciejewski's joint work with Hanghang Tong and Jingrui He's group, "Privacy-Preserving Graph Machine Learning from Data to Computation: A Survey," has been accepted to SIGKDD Explorations. Congratulations!
          </span>
        ),
    },
    {
        date: "Apr. 28, 2023",
        content: (
          <span>
            Fan Lei successfully completed his PhD comprehensive examination. Congratulations!
          </span>
        ),
    },
    {
        date: "Apr. 1, 2023",
        content: (
          <span>
            Jiayi Hong has joined VADER lab as a postdoctoral research associate. Welcome Jiayi!
          </span>
        ),
    },
    {
        date: "Mar. 31, 2023",
        content: (
          <span>
            Jiaqing Chen has received the "Engineering Grad Fellowship" in Spring 2023. Congrats Jiaqing!
          </span>
        ),
    },
    {
      date: "Feb. 23, 2023",
      content: (
        <span>
          Jieqiong Zhao and Yixuan Wang et al.'s paper, "Evaluating the Impact of Uncertainty Visualization on Model Reliance," has been accepted to IEEE TVCG. Congratulations!
        </span>
      ),
    },
  ],
};
