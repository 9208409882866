/**
 * role:
 * 'pi' | 'staff' | 'postdoc' | 'phd' | 'master' | 'undergrad'
 *
 * isAlumni:
 * true | false
 */

import React from "react";

import { hrefTargetAndRelHelper } from "../utils";

import placeholder from "../images/people/person-placeholder.jpg";
import ross from "../images/people/pi/ross.jpg";
import yuxinma from "../images/people/postdoc/yuxin.png";
import jonaslukasczyk from "../images/people/postdoc/jluk.jpg";
import fanlei from "../images/people/phd/fanlei.jpeg";
import arlenfan from "../images/people/phd/arlenfan.jpg";
import ruizhang from "../images/people/phd/rui.jpeg";
import hongwang from "../images/people/phd/Hong.jpg";
import michaelsteptoe from "../images/people/phd/MichaelSteptoe.jpg";
import tiankaixie from "../images/people/phd/tiankai.jpg";
import kangjianma from "../images/people/phd/kangjian.jpg";
import chiduan from "../images/people/alumni/chiduan.jpg";
import benjaminclayton from "../images/people/master/Ben.jpg";
import olugbadebo from "../images/people/undergrad/olu.jpg";
import markmccann from "../images/people/alumni/MarkMcCann.jpg";
import yafenglu from "../images/people/alumni/Yafeng.jpeg";
import weiluo from "../images/people/alumni/WeiLuo.jpg";
import fengwang from "../images/people/alumni/Feng.jpg";
import yifanzhang from "../images/people/alumni/Yifan.jpg";
import leizhang from "../images/people/alumni/LeiZhang.jpeg";
import brandonmathis from "../images/people/alumni/BrandonMathis.jpg";
import utkarshsoni from "../images/people/alumni/Soni.jpeg";
import prannoychandra from "../images/people/alumni/Prannoy.jpg";
import yatharthsharma from "../images/people/alumni/Yatharth.jpeg";
import xingliang from "../images/people/alumni/XingLiang.jpg";
import zhengchang from "../images/people/alumni/ZhengChang.jpg";
import biwu from "../images/people/alumni/BiWu.jpg";
import thomashayden from "../images/people/alumni/Thomas.png";
import jingxianmao from "../images/people/alumni/Jingxian.jpg";
import cecilianguyen from "../images/people/alumni/Cecilia.jpg";
import michaelamurray from "../images/people/alumni/michaela.jpeg";
import bretthansen from "../images/people/alumni/Brett.png";
import rolandogarcia from "../images/people/alumni/Rolando.png";
import ryansimmons from "../images/people/alumni/Ryan.png";
import joseibarra from "../images/people/alumni/josepicture.jpg";
import ajfairfield from "../images/people/alumni/aj.jpg";
import weihaishen from "../images/people/alumni/weihaishen.png";
import ryankemmer from "../images/people/undergrad/ryankemmer.jpg";
import mayuri from "../images/people/foodenergywaterva/Mayuri.png";
import jieqiong from "../images/people/postdoc/jieqiong.png";
import yixuan from "../images/people/phd/yixuan.jpg";
import mani from "../images/people/master/mani.jpg";
import rosty from "../images/people/master/rosty.jpg";
import varun from "../images/people/phd/varun.jpg";
import jiaqing from "../images/people/phd/jiaqing.jpg";
import jiayi from "../images/people/postdoc/jiayi.jpg";
import seto from "../images/people/phd/seto.jpg";

// FEW nexus
import davewhite from "../images/people/foodenergywaterva/DaveWhite.jpg";
import giuseppemascaro from "../images/people/foodenergywaterva/GiuseppeMascaro.jpg";
import rimjhimaggarwal from "../images/people/foodenergywaterva/RimjhimAggarwal.jpg";
import hessamssarjoughian from "../images/people/foodenergywaterva/Hessam.jpg";
import leahjones from "../images/people/foodenergywaterva/LeahJones.jpg";
import mosfard from "../images/people/foodenergywaterva/MosFard.png";
import adenikeopejin from "../images/people/foodenergywaterva/AdenikeOpejin.png";
import adilmounir from "../images/people/foodenergywaterva/AdilMounir.jpg";
import xinguan from "../images/people/foodenergywaterva/XinGuan.jpg";

export const sortByRoleRuleObj = {
  pi: 0,
  staff: 1,
  postdoc: 2,
  phd: 3,
  master: 4,
  undergrad: 5,
};

export const peopleData = [
  {
    name: "Dr. Ross Maciejewski",
    role: "pi",
    isAlumni: false,
    isLabMember: true,
    inProject: ["food-energy-water-va", "spatiotemporal-va", "xai"],
    title: "Professor",
    bio: (
      <p>
        Ross Maciejewski is a Professor at Arizona State University and Director of the School of Computing and Augmented Intelligence. He serves as the co-Director of the Center for Accelerating Operational Efficiency - a Department of Homeland Security Center of Excellence. His primary research interests are in the areas of visualization and explainable AI. He has served on the organizing committees for the IEEE Conference on Visual Analytics Science and Technology and the IEEE/VGTC EuroVis Conference, and he currently serves as the co-chair of the Visualization Executive Committee (VEC). Professor Maciejewski is a recipient of an NSF CAREER Award (2014), and his work has been recognized through a variety of awards at the IEEE Visual Analytics Contest (2010, 2013, 2015), a best paper award in EuroVis 2017, and CHI Honorable Mention Awards (2018, 2022).
      </p>
    ),
    image: ross,
    homepage: "http://rmaciejewski.faculty.asu.edu/",
  },
  {
    name: "Jieqiong Zhao",
    role: "postdoc",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Postdoctoral Research Associate",
    bio: "Jieqiong Zhao was a Postdoctoral Research Associate in the School of Computing and Augmented Intelligence at Arizona State University. She received her Ph.D. degree in Computer Engineering from Purdue University in 2020. Her broad research interests include visual analytics, information visualization, human-computer interaction, and applied artificial intelligence and machine learning.",
    image: jieqiong,
    homepage: "https://jieqiongzhao.github.io/",
  },
  {
    name: "Jiayi Hong",
    role: "postdoc",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "Postdoctoral Research Associate",
    bio: "Jiayi Hong is a Postdoctoral Research Scholar in the School of Computing and Augmented Intelligence at Arizona State University. She received her Ph.D. degree in Sciences et Technologies de l'Information et de la Communication, from AVIZ INRIA, Université Paris Saclay in 2023, supervised by Tobias Isenberg. She obtained her B.Eng degree in Industrial Design, Zhejiang University in 2018 and M.S. degree in Computer Science from the University of Bristol in 2019. Her broad research interests include human-computer interaction, visualization in 3D and 2D, and applied artificial intelligence. ",
    image: jiayi,
    homepage: "https://jiayihong.info/",
  },
  {
    name: "Yuxin Ma",
    role: "postdoc",
    isAlumni: true,
    isLabMember: true,
    inProject: ["food-energy-water-va"],
    title: "Postdoctoral Research Associate",
    bio: "Yuxin Ma was a Postdoctoral Research Associate in the School of Computing and Augmented Intelligence at Arizona State University. He received his B.S. and Ph.D. from Zhejiang University, China in 2012 and 2017, respectively. He worked as an intern researcher in SeSaMe (Sensor-enhanced Social Media) Center, National University of Singapore, in 2016. His current research interests are Information Visualization and Visual Analytics",
    image: yuxinma,
    homepage: "https://mayuxin.me",
  },
  {
    name: "Jonas Lukasczyk",
    role: "postdoc",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Postdoctoral Research Associate",
    bio: (
      <p>
        Jonas Lukasczyk was a postdoctoral research associate at the School of
        Computing and Augmented Intelligence at Arizona State University. He
        received his Ph.D. degree in Computer Science from the Technical
        University of Kaiserslautern (Germany) in 2019. His work focuses on the
        analysis and visualization of large-scale datasets by combining methods
        from topological analysis, feature characterization and tracking, visual
        analytics, and high-performance computing.
      </p>
    ),
    image: jonaslukasczyk,
    homepage: "https://jluk.de",
  },
  {
    name: "Hong Wang",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va"],
    title: "PhD Student",
    bio: "Hong Wang was a Ph.D student in the School of Computing and Augmented Intelligence at Arizona State University.  He obtained his B.S. from the University of Nevada, Las Vegas. His research interests include Data Visualization and Human Computer Interaction.",
    image: hongwang,
  },
  {
    name: "Rui Zhang",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va"],
    title: "PhD Student",
    bio: "Rui Zhang was a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. She received her B.S. degree from Zheng Zhou University, Henan, China, M.S. degree from Beijing University of Posts and Telecommunications, Beijing, China. Her research interests include Data Visualization and Modeling.",
    image: ruizhang,
  },
  {
    name: "Michael Steptoe",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: ["xai"],
    title: "PhD Student",
    bio: "Michael Steptoe was a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.S.E. and M.S. degrees from ASU. His research interests include data visualization and mobile applications.",
    image: michaelsteptoe,
    linkedin: "https://www.linkedin.com/in/michaelsteptoe",
  },
  {
    name: "Tiankai Xie",
    role: "postdoc",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "Postdoctoral Research Associate",
    bio: "Tiankai Xie is a Postdoctoral Research Scholar in the School of Computing and Augmented Intelligence at Arizona State University. He received his Ph.D. degree in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.S. degree from Beijing Forestry University, Beijing, China and M.S. degree from Stevens Institute of Technology, New Jersey. His research interests include Data Visualization and Machine Learning.",
    image: tiankaixie,
    homepage: "https://tiankaixie.com",
  },
  {
    name: "Tiankai Xie",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Tiankai Xie Michael Steptoe was a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. He received his Ph.D. degree in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.S. degree from Beijing Forestry University, Beijing, China and M.S. degree from Stevens Institute of Technology, New Jersey. His research interests include Data Visualization and Machine Learning.",
    image: tiankaixie,
    homepage: "https://tiankaixie.com",
  },
  {
    name: "Kangjian Ma",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Kangjian Ma was a Master's student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.E. degree from Heilongjiang University, Heilongjiang, China and M.S. degree from Harbin Institute of Technology, Heilongjiang, China. His research interests include Data Visualization, Geospatial Information Analytics and Machine Learning.",
    image: kangjianma,
  },
  {
    name: "Chi Duan",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Chi Duan is a Master's student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his M.S. degree from Arizona State University, Tempe and B.E. degree from Nanjing University of Aeronautics and Astronautics, Nanjing, China. His research interests include Data Visualization, Visual Analytics and Machine Learning.",
    image: chiduan,
  },
  {
    name: "Fan Lei",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Fan Lei is a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his M.S. degree from Durham University, United Kingdom. He received his Dipl.-Ing. (BEng) degree from Lübeck University of Applied Sciences, Lübeck, Schleswig-Holstein, Germany and his BEng degree from East China University of Science and Technology, Shanghai, China. His research interests include Data Visualization, Data Mining and Machine Learning.",
    image: fanlei,
  },
  {
    name: "Arlen Fan",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Arlen Fan is a Ph.D student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.S. in Electrical and Computer Enginering at the University of Rochester. He worked at Micron Technology and Hessaire Products. He joined the lab in 2019 and his research interests are data visualization and machine learning.",
    image: arlenfan,
  },
  {
    name: "Yixuan Wang",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Yixuan Wang is a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. She obtained his B.S. degree from Central South University, Changsha, China and M.S. degree from Northeastern University, Boston. Her research interests include Data Visualization and Machine Learning.",
    image: yixuan,
  },
  {
    name: "Varun Srivastava",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Varun Srivastava is a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. He is from New Delhi of India and finished his Bachelor's degree in Mechanical Engineering from SRM University, Chennai, India. Graduated in May 2017. He did his Master's degree in Industrial Engineering from Purdue University, West Lafayette, IN. in December of 2018. After graduating from Purdue, He joined a tech startup called GreenChoice, based out of Boston. Worked there as a Co-Founder and Data Scientist from January 2019 to April 2021. Now joined ASU as a Ph.D. student majoring in Industrial Engineering, and co-advised by Professor Ross Maciejewski and Professor Jorge Sefair. His research interests are Data Visualization, Artificial Intelligence, and Operations Research, especially in the field of Sustainability.",
    image: varun,
  },
  {
    name: "Jiaqing Chen",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Jiaqing Chen is a Ph.D. student majoring in Computer Science in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his B.E. in Computer Science and Technology from Wuhan University of Technology, Hubei, P.R.China and M.S. in Computer Science from University of California, Riverside, California, U.S.. His research interests include interactive machine learning and explainable AI with visualization tools and methodologies.",
    image: jiaqing,
    homepage: "https://jiaqing-asu.github.io/",
  },
  {
    name: "Christian Seto",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Christian Seto is a Ph.D. student studying data science in the School of Computing and Augmented Intelligence at Arizona State University (ASU).  He received his B.S.Eng. degree in industrial engineering and his M.S. in industrial engineering with a focus on industrial statistics both from ASU.  He is currently employed part-time as a principal industrial engineer at Northrop Grumman working in satellite manufacturing.  His research interests include Data Visualization, Data Mining, Computational Statistics, and Time Series Analysis and Forecasting.",
    image: seto,
  },
  {
    name: "Manikanta Mudara",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Manikanta Mudara is a Master's student in the School of Computing and Augmented Intelligence at Arizona State University.He got his Bachelor's degree in Information Technology from National Institute of Technology, Raipur, India. His research areas of interest include Data visualization, Data Analytics and Machine Learning.",
    image: mani,
    linkedin: "https://www.linkedin.com/in/manikanta-mudara/",
  },
  {
    name: "Rosty Hnatyshyn",
    role: "phd",
    isAlumni: false,
    isLabMember: true,
    inProject: [],
    title: "PhD Student",
    bio: "Rosty Hnatyshyn is a Ph.D. student in the School of Computing and Augmented Intelligence at Arizona State University. He recieved his B.S degree from Rowan University, New Jersey. His research interests include Data Visualization, Computer Graphics and High Performance / Distributed Computing.",
    image: rosty,
  },
  {
    name: "Benjamin Clayton",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Benjamin Clayton was a M.S. student in the School of Computing, Informatic, and Decision Systems Engineering at Arizona State University. He obtained a B.S in Computer Science from Arizona State University. His research interests include Data Visualization, Graph Mining, and Machine Learning. ",
    image: benjaminclayton,
  },
  {
    name: "Olu Gbadebo",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Olu Gbadebo was an undergraduate B.S. student majoring in Computer Science and minoring in Statistics at Arizona State University. He is set to obtain his undergraduate degree by December, 2019. His research interests include Data Visualization, Machine Learning, and Data Analytics.",
    image: olugbadebo,
  },
  {
    name: "Mark McCann",
    role: "staff",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Research Software Engineer",
    bio: (
      <p>
        Mark McCann was a Research Software Engineer for the{" "}
        <a href="https://globalsecurity.asu.edu/" {...hrefTargetAndRelHelper}>
          Global Security Initiative
        </a>{" "}
        at Arizona State University (ASU). He was previously a Research Software
        Engineer at the GeoDa Center for Geospatial Analysis and Computation at
        ASU. Prior to researching at ASU, Mark McCann Recieved his Ph.D. degree
        in Computer Science from Princeton University, M.Sc. and B.Sc. degrees
        at University of British Columbia. His areas of expertise include:
        Geographical Visualization, Fault-Tolerant Computing, Computer
        Arithmetic.
      </p>
    ),
    image: markmccann,
  },
  {
    name: "Yafeng Lu",
    role: ["postdoc", "phd"],
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va", "xai"],
    title: "Postdoctoral Research Associate, PhD Student",
    bio: "Yafeng Lu was a Postdoctoral Research Associate in the School of Computing and Augmented Intelligence at Arizona State University. She received her Ph.D. degree in Computer Science from Arizona State University, Masters and Bachelor degrees from Northeastern University, China. Her current research interests are in Data Analysis and Visualization.",
    image: yafenglu,
    homepage: "http://www.public.asu.edu/~lyafeng/",
  },
  {
    name: "Wei Luo",
    role: "postdoc",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Postdoctoral Research Associate",
    bio: "Wei Luo was a Postdoctoral Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University. Prior to attending ASU, Wei Luo received his Ph.D degree in Geography Department at Penn State University, M,A. degree in Geography Department at University at Buffalo, and B.S. degree of Geographical Information System at Northwest University in China. His research interests include: Cartography, Geovisualization, Geo-Social Visual Analytics, Spatial Analysis, Network Analysis, Complex Systems, Machine Learning, GEOINT, GeoInformatics, and Epidemic Modeling with applications to the geographic components of Big Data, Climate Change, Water Security, Economics, Social Media, Urban Dynamics, Spatial Epidemiology/Public Health, International Trade, Crime Analysis, and Crisis Management.",
    image: weiluo,
  },
  {
    name: "Feng Wang",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va"],
    title: "PhD Student",
    bio: "Feng Wang was a PhD student in the School of Computing and Augmented Intelligence at Arizona State University. After graduation, he accepted a job at GE Global Research as a Information Visualization Researcher. Prior to attending ASU, he obtained his Masters degree from the University of Science and Technology of China and a B.S. from Shandong University, China. His research interests include data visualization and data mining.",
    image: fengwang,
    linkedin: "https://www.linkedin.com/in/feng-wang-36926442/",
  },
  {
    name: "Yifan Zhang",
    role: "phd",
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va"],
    title: "PhD Student",
    bio: "Yifan Zhang was a PhD student in the School of Computing and Augmented Intelligence at Arizona State University. After graduation, he accepted a job at ESRI. His research interests include information visualization, visual analytics and computer graphics.  Prior to attending ASU, he obtained a B.S. degree from the Beijing Institute of Technology, China.",
    image: yifanzhang,
    linkedin: "https://www.linkedin.com/in/yifantastic",
  },
  {
    name: "Lei Zhang",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Lei Zhang was a M.S. student in the School of Computing, Informatics, and Decision Systems Engineering at Arizona State University. He obtained a B.S. in Computer Science from Zhejiang University. He worked as an intern researcher in SeSaMe (Sensor-enhanced Social Media) Center, National University of Singapore, in 2017. His research interests include Data Visualization and Computer Graphics.",
    image: leizhang,
  },
  {
    name: "Weihai Shen",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Weihai Shen was a M.S. student in the School of Computing, Informatic, and Decision Systems Engineering at Arizona State University from 2018 to 2020. He obtained a B.S in Geographic Information System from Nanjing University of Posts and Telecommunications. Prior to Arizona State University, he worked as a data and software engineer for four years at 58&Ganji, Baidu and ByteDance. After graduation, he continued his research in Visualization Analytics at Stony Brook University as a PhD student.",
    linkedin: "https://www.linkedin.com/in/tall-cool-13yrs/",
    image: weihaishen,
  },
  {
    name: "Brandon Mathis",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Brandon Mathis was a M.S. student in the School of Computing, Informatics, and Decision Systems Engineering at Arizona State University. He obtained a B.S. in Computer Science with a concentration in Computer Security and a B.S. in Mathematics from Fairmont State University. His research interests include Data Visualization, Computer Vision, and Machine Learning.",
    image: brandonmathis,
  },
  {
    name: "Utkarsh Soni",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Utkarsh was a Master student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his Bachelor Degree in Information Technology from IIIT Gwalior, India. His research interests include Data Visualization and Data Mining.",
    linkedin: "https://www.linkedin.com/in/utkarshsoni",
    image: utkarshsoni,
  },
  {
    name: "Prannoy Chandra",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Prannoy was a Master student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his Bachelor Degree in Computer Science and Engineering from College of Engineering Guindy, Anna University, India. His research interests include Data Visualization, and Visual Analytics.",
    image: prannoychandra,
  },
  {
    name: "Yatharth Sharma",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Yatharth was a Master student in the School of Computing and Augmented Intelligence at Arizona State University. He obtained his Bachelor Degree in Information Technology from Jaypee Institute of Information Technology, India. His research interests include Data Visualization, Data Mining and Social Media Analysis.",
    linkedin: "https://www.linkedin.com/in/yatharthsharma91",
    homepage: "https://branded.me/yatharthsharma",
    image: yatharthsharma,
  },
  {
    name: "Xing Liang",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Xing Liang was a Master's student in the School of Computing and Augmented Intelligence at Arizona State University. After graduation, he accepted a job at Amazon. His research interests include information visualization and visual analytics. ",
    linkedin: "https://www.linkedin.com/pub/liang-xing/59/5a7/a7b",
    image: xingliang,
  },
  {
    name: "Zheng Chang",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Zheng Chang was a Master's student in the School of Computing and Augmented Intelligence at Arizona State University. After graduation, he accepted a job at MathWorks. His research interests include computational steering environment and information visualization. Prior to attending ASU, he obtained a B.S. degree from the Nanjing University of Science and Technology, China.",
    linkedin: "https://www.linkedin.com/in/zhengchang",
    image: zhengchang,
  },
  {
    name: "Mayuri Roy Choudhury",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Mayuri is a PhD student in the School of Sustainability. She has completed her Master’s degree in Public Policy with a concentration in Environmental Policy from Arizona State University. Besides this, she also has a post graduate diploma in Human Rights from one of the top Law schools in India. She has worked as a Researcher for SRP, City of Phoenix, and the Mayor of Phoenix. In 2018, Mayuri also worked as a Research Associate in one of the top 30 global business schools, the Indian School of Business (ISB). Her research interests are in nexus research, sustainable energy, statistical modelling, environmental policy analysis and application of data science in advance sustainability research.",
    image: mayuri,
  },
  {
    name: "Bi Wu",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Bi Wu obtained his Masters degree in September, 2014 from the School of Computing and Augmented Intelligence at Arizona State University. His Master's thesis is entitled: Cascading CurtainMap: An Interactive Visualization for Depicting Large and Flexible Hierarchies. His work at ASU focused on Computer Graphics, Data Visualization and GUI Development. Prior to attending ASU, he obtained a B.S. from the University of Electronic Science and Technology of China. After graduation, he accepted a position as a Software Engineer at PayPal.",
    image: biwu,
  },
  {
    name: "Thomas Hayden",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Thomas Hayden obtained his Masters degree in May, 2014 from the School of Computing and Augmented Intelligence at Arizona State University. His Master's thesis was entitled: Cluster Metrics and Temporal Coherency in Pixel Based Matrices. After graduation, he continued in his current position as a Senior Software Developer at Edgenuity.",
    image: thomashayden,
  },
  {
    name: "Jingxian Mao",
    role: "master",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Master's Student",
    bio: "Jingxian Mao obtained his Masters degree in December, 2012 from the School of Computing and Augmented Intelligence at Arizona State University. His Master's thesis was entitled: Multimodal Data Fusion As a Predictior of Missing Information in Social Networks. After graduation, he accepted a position as a System Analyst as part of InforSol's Business Intelligence Institute program.",
    image: jingxianmao,
  },
  {
    name: "Ryan Kemmer",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: ["spatiotemporal-va"],
    title: "Undergraduate Student",
    bio: "Ryan Kemmer is an undergraduate B.S. student majoring in Computer Science with a Certificate in Informatics, set to graduate in the Fall of 2020. He worked as a Research Intern at the National University of Singapore, and as a Data Engineering Intern at Experian. He joined the lab in the Fall of 2019 and is mostly involved with multidisciplinary research projects. His research interests include group decision-making and machine learning.",
    linkedin: "https://www.linkedin.com/in/ryankemmer/",
    image: ryankemmer,
  },
  {
    name: "Cecilia Nguyen",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Cecilia was an undergraduate student in the School of Computing, Informatics, and Decision Systems. Her research interests include Data Visualization and anything that's a hybrid between arts/media.",
    linkedin: "https://www.linkedin.com/in/cecilia-nguyen-366207133",
    image: cecilianguyen,
  },
  {
    name: "Michaela Murray",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Michaela was a senior in high school at BASIS Chandler that graduated in May 2018. She is continuing her studies at Stanford University and is majoring in computer science. She is also actively performing in chamber groups. Michaela's research interests include data visualization, data analytics, data mining, and artificial intelligence. ",
    image: michaelamurray,
  },
  {
    name: "Brett Hansen",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Brett was an undergraduate student at the School of Computing, Informatics, and Decision Systems Engineering and the College of Liberal Arts and Sciences pursuing bachelor's degrees in computer science, mathematics, and economics. His research interests include data visualization and economic networks. He is currently working at Amazon.",
    image: bretthansen,
    linkedin: "https://www.linkedin.com/in/BrettJHansen",
  },
  {
    name: "Rolando Garcia",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Rolando Garcia was an Undergraduate Research Assistant at the Visual Analytics and Data Exploration Research (VADER) Lab at Arizona State University (ASU). The overarching theme of his undergraduate research was to create technologies to help people understand and work effectively with large sets of data. His research exprience lies primarily in Visual Analytics, and Machine Learning. Rolando was selected for honorable mention for the Computing Research Association's (CRA) Outstanding Undergraduate Researcher Award (2017). He has presented three posters and given two talks at national symposiums and an IEEE workshop. He graduated with a B.S. in Computer Science from ASU in May 2017, and started graduate school at UC Berkeley in Fall 2017.",
    image: rolandogarcia,
  },
  {
    name: "Ryan Simmons",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Ryan was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University. He graduated with a B.S. in Computer Science from ASU in May 2017. After graduation, he accepted a position as a Cyber Security Research Engineer at Honeywell. His research interests include visual analytics and data mining. ",
    image: ryansimmons,
  },
  {
    name: "Sagarika Kadambi",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Sagarika was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University.",
    image: placeholder,
  },
  {
    name: "Sergio Felix",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Sergio was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University.",
    image: placeholder,
  },
  {
    name: "Daniel Martin",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Daniel was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University.",
    image: placeholder,
  },
  {
    name: "Christopher Kyle",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Christopher was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University.",
    image: placeholder,
  },
  {
    name: "Alexandra Porter",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Alex was an Undergraduate Research Assistant in the School of Computing and Augmented Intelligence at Arizona State University.",
    image: placeholder,
  },
  {
    name: "Jose Ibarra",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "Jose obtained his Bachelors degree in May, 2014 from the School of Computing and Augmented Intelligence at Arizona State University. His area of research was data visualization. Outside of research he developed iPhone apps and webpages. When not doing school work or researching, he likes to play racquetball and tennis. After graduation, he accepted a position at General Motors.",
    image: joseibarra,
  },
  {
    name: "A. J. Fairfield",
    role: "undergrad",
    isAlumni: true,
    isLabMember: true,
    inProject: [],
    title: "Undergraduate Student",
    bio: "A.J. obtained his Undergraduate degree in May, 2013 from the School of Computing and Augmented Intelligence at Arizona State University. A.J.'s Computer Science degree is specialized in Software Engineering and Video Game Design. After graduation, he accepted a position as a Software Development Engineer in Test (SDET) in the DirectX department at Microsoft.",
    image: ajfairfield,
  },
  {
    name: "Dr. Dave White",
    role: "pi",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "Professor",
    bio: "Dave White is a Professor in the Arizona State University School of Community Resources and Development where also serves as Principal Investigator and Director of the ASU Decision Center for a Desert City. Supported by the the National Science Foundation since 2004 with more than $18M, DCDC at ASU conducts climate, water, and decision research and develops innovative tools to bridge the boundary between scientists and decision makers. Professor White is widely published and cited; his work has been covered in popular media including The New York Times, Wall Street Journal, and National Public Radio. Dr. White is a recipient of the President's Medal for Social Embeddedness from Arizona State University and the Celebrating Natural Resources Award from the University of Idaho. He received his Ph.D. in Forestry from Virginia Tech.",
    homepage: "https://sustainability.asu.edu/person/dave-white/",
    image: davewhite,
  },
  {
    name: "Dr. Giuseppe Mascaro",
    role: "pi",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "Assistant Professor",
    bio: 'Giuseppe Mascaro earned a "Laurea" (Master of Science) in Civil Engineering in and a Ph.D. in Hydrology in 2008 from the University of Cagliari, Italy. Currently, he is an Assistant Professor at the School of Sustainable Engineering and the Built Environment and a fellow of the Global Security Initiative at Arizona State University. In addition to the study of the food-energy-water nexus, his research interests include stochastic hydrology, statistical downscaling, hydrometeorology, and watershed modeling.',
    homepage: "http://hydrology.asu.edu/wiki/index.php/Gmasc",
    image: giuseppemascaro,
  },
  {
    name: "Dr. Rimjhim Aggarwal",
    role: "pi",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "Associate Professor",
    bio: 'Rimjhim Aggarwal is an Associate Professor and Senior Sustainability Scientist with the Julie Ann Wrigley Global Institute of Sustainability at ASU. She received her Ph.D. in Economics from Cornell University. In recent work she has examined how farmers and irrigation district managers in Central Arizona respond to drought conditions, specifically focusing on their willingness to adopt water conserving technologies and practices, and the impact of their decision-making on aggregate agricultural land use and water consumption. She is also currently engaged in research projects examining the impacts of globalization and climate change on agricultural and water governance, farm livelihoods, and food security in India, Nepal, and Thailand. Dr. Aggarwal has also worked as a senior consultant for the United Nations University\'s World Institute for Development Economics Research and the World Bank. In recognition of her efforts in "use-inspired research" and "community outreach," Dr. Aggarwal was awarded the Arizona State University President\'s Award for Sustainability in 2010.',
    homepage: "https://sustainability.asu.edu/person/rimjhim-aggarwal/",
    image: rimjhimaggarwal,
  },
  {
    name: "Dr. Hessam S. Sarjoughian",
    role: "pi",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "Associate Professor",
    bio: (
      <p>
        Hessam S. Sarjoughian is an Associate Professor of Computer Science and
        Computer Engineering in the School of Computing, Informatics, and
        Decision Systems Engineering (CIDSE) at Arizona State University (ASU),
        Tempe, AZ, USA. He is co-founder and co-director of the Arizona Center
        for Integrative Modeling & Simulation (ACIMS). Sarjoughian is a Founding
        Member of the Certified Modeling & Simulation Profession. He is a
        Certified Modeling & Simulation Professional. Sarjoughian's industry
        experience has been with IBM and Honeywell. His research at ASU has been
        funded by NSF, Intel, Lockheed Martin, and and Boeing among others.
        <br />
        Sarjoughian's research focuses on composable modeling theories and
        methodologies that can lead to scalable simulation frameworks, tools,
        and practices. Key areas of research include poly-formalism model
        composability, collaborative visual modeling, agent-based simulation,
        distributed simulation, and software architecture. His educational aim
        is to help Modeling & Simulation become a discipline.
        <br />
        Sarjoughian's work has been adopted and applied in industrial, research,
        and educational settings with use in enterprise computing systems,
        cyber-physical systems, and human-natural systems. Sarjoughian has been
        the architect for the DEVS-Suite simulation and CoSMoS modeling
        frameworks and tools which are being used in universities and research
        institutes in several dozen countries across the Americas, Europe, Asia,
        Middle East, and Africa.
      </p>
    ),
    image: hessamssarjoughian,
  },
  {
    name: "Leah Jones",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Leah Jones is a Ph.D. student in the School of Sustainability at Arizona State University. She received her B.S. in environmental science from the University of Virginia. Her research interests include water management and international development.",
    linkedin: "https://www.linkedin.com/in/j-leah-jones-652b4984/",
    image: leahjones,
  },
  {
    name: "Mos Fard",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Mos Fard is pursuing a Ph.D. in Computer Science in the School of Computing, Informatics, and Decision Systems Engineering at Arizona State University, Tempe, AZ, USA. He is passionate about software design and architecture. He earned Masters of Engineering in the School of Electrical and Computer Engineering at University of Tehran, Tehran, Iran. His research focused on Modeling & Simulation (DEVS formalism and visual behavior modeling in CoSMoS). He has around 10 years of industry experience in analysis, design, development, and maintenance of Windows/Web Applications. ",
    image: mosfard,
  },
  {
    name: "Adenike Opejin",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Adenike Opejin is a PhD student in the School of Sustainability at Arizona State University. She earned master's degree in Urban and Environmental Planning from ASU, and B.S in Urban Planning from Obafemi Awolowo University, Nigeria. Her research interests are in urban food system sustainability, land use change, resource and waste management, and climate change adaptation in cities. She has worked with municipal governement both in the U.S and Nigeria and worked as an urban designer in private firms. In 2016, she worked as the accreditation coordinator in the School of Urban Planning and Geographical Sciences at ASU to secure a 5-year accreditation with the planning professional body. ",
    image: adenikeopejin,
  },
  {
    name: "Adil Mounir",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Adil Mounir is pursuing a PhD in Hydrosystems Engineering at Arizona State University. He earned a Master of Science degree in Civil Engineering at Ohio University and completed a Bachelor of Engineering in Civil Engineering at Ecole Mohammadia d'Ingenieurs (Rabat, Morocco). His research interests include hydrologic and hydraulic modeling, optimal operation of river-reservoirs systems, and hydro-economic modeling. ",
    image: adilmounir,
  },
  {
    name: "Xin Guan",
    role: "phd",
    isAlumni: false,
    isLabMember: false,
    inProject: ["food-energy-water-va"],
    title: "PhD Student",
    bio: "Xin Guan is a Ph.D. student in Hydrosystems Engineering in the School of Sustainable Engineering and the Built Environment at Arizona State University. She obtained her Master degree from Beijing Normal University, China. Currently she is using the Water Evaluation And Planning System (WEAP) model to conduct simulations for the food-energy-water (FEW) nexus in Phoenix Active Management Area (AMA). ",
    image: xinguan,
  },
];
